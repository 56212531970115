export const blogInnerStatic = {
  crumbsNav: [
    {
      id: 1,
      link: '/',
      title_ua: 'Головна',
      title_ru: 'Главная',
      title_en: 'Home',
    },
    {
      id: 2,
      link: '/blog/',
      title_ua: 'Блог',
      title_ru: 'Блог',
      title_en: 'Blog',
    },
    
  ],
};
